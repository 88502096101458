<!-- <app-header-two></app-header-two> -->

<div class="home-area home-slides-two owl-carousel owl-theme">
    <div class="banner-section item-bg3">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content">
                        <span class="sub-title">Enjoy Your Happiness</span>
                        <h1>Family! The key to Your Balancing Life</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                        <div class="btn-box">
                            <a routerLink="/contact" class="optional-btn">Contact Us <span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="banner-section item-bg1">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content">
                        <span class="sub-title">Enjoy Your Happiness</span>
                        <h1>Execute Happiness by Incredible Opportunities</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                        <div class="btn-box">
                            <a routerLink="/contact" class="default-btn">Get A Quote <span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="banner-section item-bg4">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content">
                        <span class="sub-title">Enjoy Your Happiness</span>
                        <h1>Being Fit is Your Right, <br>not a Privilege</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                        <div class="btn-box">
                            <a routerLink="/contact" class="optional-btn">Contact Us <span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="about-area ptb-100 bg-f8f8f8">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-title">
                    <span>About Us</span>
                    <h2>We Are Award Winning Company</h2>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-text">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. </p>
                    <a routerLink="/about" class="read-more-btn">More About Us <i class="flaticon-right-chevron"></i></a>
                </div>
            </div>
        </div>

        <div class="about-boxes-area">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-about-box">
                        <div class="icon">
                            <i class="flaticon-care"></i>
                        </div>
                        <h3>Trustworthy Company</h3>
                        <p>Lorem ipsum dolor sit amet,  adipiscing consectetur gravida elit</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-about-box">
                        <div class="icon">
                            <i class="flaticon-policy"></i>
                        </div>
                        <h3>100% Money Back Guarantee</h3>
                        <p>Lorem ipsum dolor sit amet,  adipiscing consectetur gravida elit</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3 offset-sm-3 col-sm-6">
                    <div class="single-about-box">
                        <div class="icon">
                            <i class="flaticon-like"></i>
                        </div>
                        <h3>Anytime Cancellation</h3>
                        <p>Lorem ipsum dolor sit amet,  adipiscing consectetur gravida elit</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-area ptb-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Services</span>
            <h2>Types of Solutions</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="image">
                        <img src="assets/img/services-image/img1.jpg" alt="image">
                    </div>

                    <div class="content">
                        <h3><a routerLink="/home-insurance">Home Insurance</a></h3>
                        <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt sed ut incididunt labore et dolore.</p>
                        <a routerLink="/home-insurance" class="read-more-btn">Learn More <i class="flaticon-right-chevron"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="image">
                        <img src="assets/img/services-image/img2.jpg" alt="image">
                    </div>

                    <div class="content">
                        <h3><a routerLink="/business-insurance">Business Insurance</a></h3>
                        <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt sed ut incididunt labore et dolore.</p>
                        <a routerLink="/business-insurance" class="read-more-btn">Learn More <i class="flaticon-right-chevron"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="image">
                        <img src="assets/img/services-image/img3.jpg" alt="image">
                    </div>

                    <div class="content">
                        <h3><a routerLink="/health-insurance">Health Insurance</a></h3>
                        <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt sed ut incididunt labore et dolore.</p>
                        <a routerLink="/health-insurance" class="read-more-btn">Learn More <i class="flaticon-right-chevron"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="image">
                        <img src="assets/img/services-image/img4.jpg" alt="image">
                    </div>

                    <div class="content">
                        <h3><a routerLink="/travel-insurance">Travel Insurance</a></h3>
                        <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt sed ut incididunt labore et dolore.</p>
                        <a routerLink="/travel-insurance" class="read-more-btn">Learn More <i class="flaticon-right-chevron"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="image">
                        <img src="assets/img/services-image/img5.jpg" alt="image">
                    </div>

                    <div class="content">
                        <h3><a routerLink="/car-insurance">Car Insurance</a></h3>
                        <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt sed ut incididunt labore et dolore.</p>
                        <a routerLink="/car-insurance" class="read-more-btn">Learn More <i class="flaticon-right-chevron"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="image">
                        <img src="assets/img/services-image/img6.jpg" alt="image">
                    </div>

                    <div class="content">
                        <h3><a routerLink="/life-insurance">Life Insurance</a></h3>
                        <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt sed ut incididunt labore et dolore.</p>
                        <a routerLink="/life-insurance" class="read-more-btn">Learn More <i class="flaticon-right-chevron"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="find-agent-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Find An Insurance Agent</h2>
        </div>
        
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="find-agent-content">
                    <div class="row">
                        <div class="col-lg-12 col-md-6 col-sm-6">
                            <div class="box">
                                <div class="icon">
                                    <i class="flaticon-home-insurance"></i>
                                </div>

                                <h3>Find Insurance From The Best</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-6 col-sm-6">
                            <div class="box">
                                <div class="icon">
                                    <i class="flaticon-briefcase"></i>
                                </div>

                                <h3>Choose What Suits You</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="find-agent-contact-info">
                    <ul>
                        <li>
                            <div class="icon">
                                <i class="flaticon-call"></i>
                            </div>

                            <span>Talk to Our Agent</span>
                            <a href="tel:1518285679">+1 518 285679</a>
                        </li>

                        <li>
                            <cite>Or</cite>
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-email"></i>
                            </div>

                            <span>Drop us an Email</span>
                            <a href="mailto:hello@truz.com">hello@truz.com</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="feedback-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Feedback</span>
            <h2>What Client Says About Us</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="feedback-item-slides owl-carousel owl-theme">
            <div class="single-feedback-item">
                <div class="feedback-desc">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit volupt accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae illo.</p>
                </div>

                <div class="client-info">
                    <img src="assets/img/client-image/img1.jpg" alt="image">
                    <h3>David Luis</h3>
                    <span>Founder & CEO</span>
                </div>
            </div>

            <div class="single-feedback-item">
                <div class="feedback-desc">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit volupt accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae illo.</p>
                </div>

                <div class="client-info">
                    <img src="assets/img/client-image/img2.jpg" alt="image">
                    <h3>Steven Smith</h3>
                    <span>Developer</span>
                </div>
            </div>

            <div class="single-feedback-item">
                <div class="feedback-desc">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit volupt accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae illo.</p>
                </div>

                <div class="client-info">
                    <img src="assets/img/client-image/img3.jpg" alt="image">
                    <h3>Sarah Lucy</h3>
                    <span>Designer</span>
                </div>
            </div>

            <div class="single-feedback-item">
                <div class="feedback-desc">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit volupt accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae illo.</p>
                </div>

                <div class="client-info">
                    <img src="assets/img/client-image/img4.jpg" alt="image">
                    <h3>James Anderson</h3>
                    <span>Manager</span>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="ctr-area">
    <div class="container">
        <div class="ctr-content">
            <h2>Insurances For Your Child's Future</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
            <a routerLink="/contact" class="default-btn">Get A Quote <i class="flaticon-right-chevron"></i><span></span></a>
        </div>

        <div class="ctr-image">
            <img src="assets/img/ctr-img.jpg" alt="image">
        </div>

        <div class="shape">
            <img src="assets/img/bg-dot3.png" alt="image">
        </div>
    </div>
</section>

<section class="quote-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="quote-content">
                    <h2>Get A Free Quote</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                    <div class="image">
                        <img src="assets/img/img1.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="tab quote-list-tab">
                    <ul class="tabs">
                        <li><a href="#">Home</a></li>
                        <li><a href="#">Business</a></li>
                        <li><a href="#">Health</a></li>
                        <li><a href="#">Car</a></li>
                        <li><a href="#">Life</a></li>
                    </ul>

                    <div class="tab_content">
                        <div class="tabs_item">
                            <p>Our experts will reply you with a quote very soon</p>
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Your Email">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Phone">
                                </div>
                                <div class="form-group">
                                    <select>
                                        <option>Property Used For</option>
                                        <option>Home Insurance</option>
                                        <option>Business Insurance</option>
                                        <option>Health Insurance</option>
                                        <option>Travel Insurance</option>
                                        <option>Car Insurance</option>
                                        <option>Life Insurance</option>
                                    </select>
                                </div>
                                <button type="submit" class="default-btn">Get A Free Quote <span></span></button>
                            </form>
                        </div>

                        <div class="tabs_item">
                            <p>Our experts will reply you with a quote very soon</p>
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Your Email">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Phone">
                                </div>
                                <div class="form-group">
                                    <select>
                                        <option>Property Used For</option>
                                        <option>Home Insurance</option>
                                        <option>Business Insurance</option>
                                        <option>Health Insurance</option>
                                        <option>Travel Insurance</option>
                                        <option>Car Insurance</option>
                                        <option>Life Insurance</option>
                                    </select>
                                </div>
                                <button type="submit" class="default-btn">Get A Free Quote <span></span></button>
                            </form>
                        </div>

                        <div class="tabs_item">
                            <p>Our experts will reply you with a quote very soon</p>
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Your Email">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Phone">
                                </div>
                                <div class="form-group">
                                    <select>
                                        <option>Property Used For</option>
                                        <option>Home Insurance</option>
                                        <option>Business Insurance</option>
                                        <option>Health Insurance</option>
                                        <option>Travel Insurance</option>
                                        <option>Car Insurance</option>
                                        <option>Life Insurance</option>
                                    </select>
                                </div>
                                <button type="submit" class="default-btn">Get A Free Quote <span></span></button>
                            </form>
                        </div>

                        <div class="tabs_item">
                            <p>Our experts will reply you with a quote very soon</p>
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Your Email">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Phone">
                                </div>
                                <div class="form-group">
                                    <select>
                                        <option>Property Used For</option>
                                        <option>Home Insurance</option>
                                        <option>Business Insurance</option>
                                        <option>Health Insurance</option>
                                        <option>Travel Insurance</option>
                                        <option>Car Insurance</option>
                                        <option>Life Insurance</option>
                                    </select>
                                </div>
                                <button type="submit" class="default-btn">Get A Free Quote <span></span></button>
                            </form>
                        </div>

                        <div class="tabs_item">
                            <p>Our experts will reply you with a quote very soon</p>
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Your Email">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Phone">
                                </div>
                                <div class="form-group">
                                    <select>
                                        <option>Property Used For</option>
                                        <option>Home Insurance</option>
                                        <option>Business Insurance</option>
                                        <option>Health Insurance</option>
                                        <option>Travel Insurance</option>
                                        <option>Car Insurance</option>
                                        <option>Life Insurance</option>
                                    </select>
                                </div>
                                <button type="submit" class="default-btn">Get A Free Quote <span></span></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="achievements-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="achievements-content">
                    <div class="title">
                        <span class="sub-title">Number</span>
                        <h2>Our Achievements</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-6 col-sm-4">
                            <div class="single-funfact">
                                <i class="flaticon-flag"></i>
                                <h3><span class="odometer" data-count="65">00</span></h3>
                                <p>Countries</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-6 col-sm-4">
                            <div class="single-funfact">
                                <i class="flaticon-group"></i>
                                <h3><span class="odometer" data-count="107">00</span> <span class="sign-icon">m</span></h3>
                                <p>Clients</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-12 col-sm-4">
                            <div class="single-funfact">
                                <i class="flaticon-medal"></i>
                                <h3><span class="odometer" data-count="150">00</span></h3>
                                <p>Wining Awards</p>
                            </div>
                        </div>
                    </div>

                    <div class="bg-dot"><img src="assets/img/bg-dot.png" alt="image"></div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="achievements-image-slides owl-carousel owl-theme">
                    <div class="single-achievements-image bg1">
                        <img src="assets/img/achievements-img1.jpg" alt="image">
                    </div>

                    <div class="single-achievements-image bg2">
                        <img src="assets/img/achievements-img2.jpg" alt="image">
                    </div>

                    <div class="single-achievements-image bg3">
                        <img src="assets/img/achievements-img3.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="team-area ptb-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Agent</span>
            <h2>Meet Our Experts</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="team-slides owl-carousel owl-theme">
            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/img2.jpg" alt="image">

                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>

                <div class="content">
                    <h3>Lee Munroe</h3>
                    <span>CEO & Founder</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/img3.jpg" alt="image">

                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>

                <div class="content">
                    <h3>Calvin Klein</h3>
                    <span>Underwriter</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/img4.jpg" alt="image">

                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>

                <div class="content">
                    <h3>Sarah Taylor</h3>
                    <span>Agent</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/img1.jpg" alt="image">

                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>

                <div class="content">
                    <h3>Alastair Cook</h3>
                    <span>Risk Manager</span>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="partner-area bg-black-color">
    <div class="container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img1.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img2.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img3.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img4.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img5.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</section>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Blog</span>
            <h2>News And Insights</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/img1.jpg" alt="image"></a>
                        <div class="date"><i class="flaticon-timetable"></i> Oct 14, 2020</div>
                    </div>

                    <div class="post-content">
                        <h3><a routerLink="/blog-details">2020 Insurance Trends And Possible Challenges</a></h3>
                        <p>Luis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <a routerLink="/blog-details" class="default-btn">Read More <span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/img2.jpg" alt="image"></a>
                        <div class="date"><i class="flaticon-timetable"></i> Oct 10, 2020</div>
                    </div>

                    <div class="post-content">
                        <h3><a routerLink="/blog-details">Global Trends in the Life Insurance Industry</a></h3>
                        <p>Luis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <a routerLink="/blog-details" class="default-btn">Read More <span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/img3.jpg" alt="image"></a>
                        <div class="date"><i class="flaticon-timetable"></i> Sep 13, 2020</div>
                    </div>

                    <div class="post-content">
                        <h3><a routerLink="/blog-details">The Best Car Insurance Companies in 2020</a></h3>
                        <p>Luis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <a routerLink="/blog-details" class="default-btn">Read More <span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="blog-notes">
                    <p>Insights to help you do what you do better, faster and more profitably. <a routerLink="/blog-right-sidebar">Read Full Blog</a></p>
                </div>
            </div>
        </div>
    </div>
</section>