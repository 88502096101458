<app-header-one></app-header-one>

<!-- <div class="page-title-area page-title-bg3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->


<section class="services-area ptb-100 pb-70 productmargin bgcolor">
    <div class="container">
        <!-- <div class="row"> -->
            <!-- <div class="col-lg-4 col-md-6 col-sm-6"> -->
                <!-- <div class="single-services-box"> -->
                    <!-- <div class="icon">
                        <i class="flaticon-home-insurance"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div> -->

                    <!-- <h3><a routerLink="/home-insurance">Home Insurance</a></h3> -->
                   
                   
                     <ul><div class="head">Product and Service Offerings:</div>
                        <div class="productlist">
                        <li> Computer Peripherals and Hardware: We offer an extensive range of computer peripherals and 
                            hardware, including keyboards, mice, monitors, printers, scanners, external storage devices, 
                            graphics cards, RAM, motherboards, and more. Our products are sourced from leading 
                            manufacturers to ensure performance, reliability, and longevity.
                            </li>
                            <li> Laptops: Ginniz Infosystems brings you a wide selection of laptops from renowned brands. 
                                Whether you need a high-performance laptop for professional use or a sleek Ultrabooks for on-
                                the-go convenience, we have the perfect solution to suit your requirements.
                                </li>
                                <li> Networking Solutions: Our networking solutions cater to businesses of all sizes, from small offices 
                                    to large enterprises. We offer routers, switches, access points, network cables, and other networking 
                                    accessories that guarantee seamless data connectivity and enhanced network performance.
                                    </li>
                                    <li> CCTV Systems: Security is of paramount importance in today's world. We provide comprehensive 
                                        CCTV systems that enable you to monitor and protect your premises effectively. Our range includes 
                                        high-definition cameras, DVRs, NVRs, and video management software.
                                        </li>
                                        <li> Biometric Machines: To bolster access control and time management, we offer cutting-edge 
                                            biometric machines, including fingerprint scanners and facial recognition systems. These solutions 
                                            ensure enhanced security and accurate attendance tracking for businesses.</li>
                                            <li>Security Systems: Ginniz Infosystems offers a range of advanced security systems to safeguard 
                                                your property and assets. From intrusion detection systems and access control solutions to alarm 
                                                systems and smart locks, we have you covered.</li></div>
                    
                </ul>
            
                    
                    <!-- <a routerLink="/home-insurance" class="read-more-btn">Learn More</a> -->

                    <!-- <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div> -->
                <!-- </div> -->
            <!-- </div> -->
<!-- 
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-insurance"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a routerLink="/business-insurance">Business Insurance</a></h3>
                    <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>
                    <a routerLink="/business-insurance" class="read-more-btn">Learn More</a>

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-health-insurance"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a routerLink="/health-insurance">Health Insurance</a></h3>
                    <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>
                    <a routerLink="/health-insurance" class="read-more-btn">Learn More</a>

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-travel-insurance"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a routerLink="/travel-insurance">Travel Insurance</a></h3>
                    <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>
                    <a routerLink="/travel-insurance" class="read-more-btn">Learn More</a>

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-car-insurance"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a routerLink="/car-insurance">Car Insurance</a></h3>
                    <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>
                    <a routerLink="/car-insurance" class="read-more-btn">Learn More</a>

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-life-insurance"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a routerLink="/life-insurance">Life Insurance</a></h3>
                    <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>
                    <a routerLink="/life-insurance" class="read-more-btn">Learn More</a>

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </div> -->
        <!-- </div> -->

<ul>
    <div class="head">Why Choose Ginniz Infosystems:</div>
    <div class="productlist">
<li>High-Quality Products: We only source products from reputable manufacturers, ensuring 
    premium quality and optimal performance.</li>
    <li>Technical Expertise: Our team of skilled professionals possesses extensive technical knowledge 
        and experience to assist you with any technology-related queries or issues.
        </li>
        <li>Custom Solutions: We understand that all client's requirements are unique. Therefore, we offer 
            tailor-made technology solutions that align with your specific needs.
            </li>
            <li>Timely Support: We pride ourselves on providing prompt and efficient customer support, ensuring 
                a seamless experience for our clients.
                </li>
                <li>Competitive Pricing: We offer competitive pricing on all our products and services, making 
                    advanced technology accessible to businesses of all sizes.
                    </li>
                    </div>
    </ul>





    </div>
</section>
<!-- 
<section class="services-area ptb-100 pb-70 productmargin bgcolor">
    <div class="container">
        </div>
</section> -->

<!-- <section class="partner-area bg-black-color">
    <div class="container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img1.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img2.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img3.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img4.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img5.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</section>

<section class="quote-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="quote-content">
                    <h2>Get a free quote</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                    <div class="image">
                        <img src="assets/img/img1.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="tab quote-list-tab">
                    <ul class="tabs">
                        <li><a href="#">Home</a></li>
                        <li><a href="#">Business</a></li>
                        <li><a href="#">Health</a></li>
                        <li><a href="#">Car</a></li>
                        <li><a href="#">Life</a></li>
                    </ul>

                    <div class="tab_content">
                        <div class="tabs_item">
                            <p>Our experts will reply you with a quote very soon</p>
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Your Email">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Phone">
                                </div>
                                <div class="form-group">
                                    <select>
                                        <option value="1">Property Used For</option>
                                        <option value="2">Home Insurance</option>
                                        <option value="0">Business Insurance</option>
                                        <option value="3">Health Insurance</option>
                                        <option value="4">Travel Insurance</option>
                                        <option value="5">Car Insurance</option>
                                        <option value="6">Life Insurance</option>
                                    </select>
                                </div>
                                <button type="submit" class="default-btn">Get A Free Quote <span></span></button>
                            </form>
                        </div>

                        <div class="tabs_item">
                            <p>Our experts will reply you with a quote very soon</p>
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Your Email">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Phone">
                                </div>
                                <div class="form-group">
                                    <select>
                                        <option value="1">Property Used For</option>
                                        <option value="2">Home Insurance</option>
                                        <option value="0">Business Insurance</option>
                                        <option value="3">Health Insurance</option>
                                        <option value="4">Travel Insurance</option>
                                        <option value="5">Car Insurance</option>
                                        <option value="6">Life Insurance</option>
                                    </select>
                                </div>
                                <button type="submit" class="default-btn">Get A Free Quote <span></span></button>
                            </form>
                        </div>

                        <div class="tabs_item">
                            <p>Our experts will reply you with a quote very soon</p>
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Your Email">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Phone">
                                </div>
                                <div class="form-group">
                                    <select>
                                        <option value="1">Property Used For</option>
                                        <option value="2">Home Insurance</option>
                                        <option value="0">Business Insurance</option>
                                        <option value="3">Health Insurance</option>
                                        <option value="4">Travel Insurance</option>
                                        <option value="5">Car Insurance</option>
                                        <option value="6">Life Insurance</option>
                                    </select>
                                </div>
                                <button type="submit" class="default-btn">Get A Free Quote <span></span></button>
                            </form>
                        </div>

                        <div class="tabs_item">
                            <p>Our experts will reply you with a quote very soon</p>
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Your Email">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Phone">
                                </div>
                                <div class="form-group">
                                    <select>
                                        <option value="1">Property Used For</option>
                                        <option value="2">Home Insurance</option>
                                        <option value="0">Business Insurance</option>
                                        <option value="3">Health Insurance</option>
                                        <option value="4">Travel Insurance</option>
                                        <option value="5">Car Insurance</option>
                                        <option value="6">Life Insurance</option>
                                    </select>
                                </div>
                                <button type="submit" class="default-btn">Get A Free Quote <span></span></button>
                            </form>
                        </div>

                        <div class="tabs_item">
                            <p>Our experts will reply you with a quote very soon</p>
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Your Email">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Phone">
                                </div>
                                <div class="form-group">
                                    <select>
                                        <option value="1">Property Used For</option>
                                        <option value="2">Home Insurance</option>
                                        <option value="0">Business Insurance</option>
                                        <option value="3">Health Insurance</option>
                                        <option value="4">Travel Insurance</option>
                                        <option value="5">Car Insurance</option>
                                        <option value="6">Life Insurance</option>
                                    </select>
                                </div>
                                <button type="submit" class="default-btn">Get A Free Quote <span></span></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->