<app-header-one></app-header-one>

<div class="page-title-area page-title-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>404 Error</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>404 Error</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="error-area ptb-100">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="error-content">
                    <img src="assets/img/404.png" alt="error">
                    <h3>Page Not Found</h3>
                    <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                    <a routerLink="/" class="default-btn">Go to Home <span></span></a>
                </div>
            </div>
        </div>
    </div>
</section>