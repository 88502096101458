<app-header-one></app-header-one>

<div class="home-area home-slides owl-carousel owl-theme">
    <div class="main-banner bgimg1">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content">
                        <span class="sub-title">Enjoy Your Happiness</span>
                        <h1>Hardware Repair and Trouble</h1>
                        <p>Hardware repair plays a crucial role in maintaining the functionality of various electronic devices and systems. When components malfunction or systems fail, the expertise of hardware repair technicians becomes indispensable. From laptops and smartphones to home appliances and industrial machinery, hardware issues can arise due to wear and tear, manufacturing defects, or external factors like power surges.</p>

                        <div class="btn-box">
                            <a routerLink="/contact" class="default-btn">Get A Quote <span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main-banner bgimg2">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content">
                        <span class="sub-title">Enjoy Your Happiness</span>
                        <h1>Diagnosing the Problem: Unveiling the Culprit</h1>
                        <p>The first step in hardware repair is diagnosing the root cause of the issue. This involves a combination of technical knowledge, diagnostic tools, and systematic troubleshooting. Repair technicians carefully examine the hardware, run diagnostics, and conduct tests to pinpoint the malfunctioning component or subsystem. This step requires a keen eye for detail and a comprehensive understanding of how various hardware components interact.

                        </p>

                        <div class="btn-box">
                            <a routerLink="/contact" class="optional-btn">Contact Us <span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main-banner bgimg3">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content">
                        <span class="sub-title">Enjoy Your Happiness</span>
                        <h1>Component Replacement: Swapping the Defective Parts</h1>
                        <p>Once the problem has been identified, the repair process often involves replacing the malfunctioning component with a new, functional one. This can range from simple tasks like replacing a damaged laptop keyboard to more complex endeavors like swapping out a faulty motherboard in a desktop computer. Proper handling of sensitive components, following manufacturer guidelines, and ensuring compatibility are all essential to successful component replacement.

                        </p>

                        <div class="btn-box">
                            <a routerLink="/contact" class="default-btn">Get A Quote <span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-boxes-area bg-f8f8f8">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="image"  style="object-fit: cover; height: 380px;">
                        <img src="/assets/img/hardware_img.jpg" alt="image">
                    </div>

                    <div class="content">
                        <h3>Hardware</h3>
                        <p>Hardware repair involves diagnosing and resolving issues with physical components of electronic devices such as computers, smartphones, and other gadgets. This process includes identifying faulty components, replacing damaged parts, soldering connections, and troubleshooting circuitry problems.Skilled technicians use specialized tools and knowledge of electronics to ensure proper functionality and extend the lifespan of devices. </p>

                        <!-- <div class="icon">
                            <img src="/assets/img/hardware_logo.jpg" width="30px" alt="image">
                        </div> -->
                        <!-- <div class="shape">
                            <img src="assets/img/umbrella.png" alt="image">
                        </div> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="image" style="object-fit: cover; height: 380px;">
                        <img src="/assets/img/laptop_img.jpg" alt="image">
                    </div>

                    <div class="content">
                        <h3>Laptop Repair</h3>
                        <p>In the world of laptop repair, skilled technicians diagnose and mend a wide array of issues to breathe new life into these indispensable devices. From troubleshooting software glitches and virus infections to replacing hardware components like malfunctioning keyboards, cracked screens, or faulty batteries, laptop repair experts employ their technical prowess to restore functionality.Armed with specialized tools, they delicately dismantle and reassemble laptops.  </p>
                        
                        <!-- <div class="icon">
                            <img src="assets/img/icon2.png" alt="image">
                        </div> -->
                        <!-- <div class="shape">
                            <img src="assets/img/umbrella.png" alt="image">
                        </div> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-box">
                    <div class="image" style="object-fit: cover; height:380px;">
                        <img src="/assets/img/cctv_img.avif" alt="image">
                    </div>

                    <div class="content">
                        <h3>CCTV Repair</h3>
                        <p> Trained technicians adept in diagnosing hardware and software issues work to identify and rectify the problem, ensuring optimal camera performance, clear video footage, and seamless monitoring.CCTV repair services play a crucial role in maintaining the integrity of security setups for both residential and commercial spaces.Whether it's a fuzzy display, distorted images, or a complete black screen, efficient CCTV repair services are essential to restore the functionality.</p>
                        
                        <!-- <div class="icon">
                            <img src="assets/img/icon3.png" alt="image">
                        </div> -->
                        <!-- <div class="shape">
                            <img src="assets/img/umbrella.png" alt="image">
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="about-area ptb-100 bg-f8f8f8">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-title">
                    <span>About Us</span>
                    <h2>We Are Award Winning Company</h2>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-text">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. </p>
                    <a routerLink="/about" class="read-more-btn">More About Us <i class="flaticon-right-chevron"></i></a>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="services-area bg-f8f8f8 pb-70">
    <div class="container">
        <div class="services-slides owl-carousel owl-theme">
            <div class="single-services-box">
                <div class="icon">
                    <i class="flaticon-home-insurance"></i>

                    <div class="icon-bg">
                        <img src="assets/img/icon-bg1.png" alt="image">
                        <img src="assets/img/icon-bg2.png" alt="image">
                    </div>
                </div>

                <h3><a routerLink="/home-insurance">Home Insurance</a></h3>
                <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>
                <a routerLink="/home-insurance" class="read-more-btn">Learn More</a>

                <div class="box-shape">
                    <img src="assets/img/box-shape1.png" alt="image">
                    <img src="assets/img/box-shape2.png" alt="image">
                </div>
            </div>

            <div class="single-services-box">
                <div class="icon">
                    <i class="flaticon-insurance"></i>

                    <div class="icon-bg">
                        <img src="assets/img/icon-bg1.png" alt="image">
                        <img src="assets/img/icon-bg2.png" alt="image">
                    </div>
                </div>

                <h3><a routerLink="/business-insurance">Business Insurance</a></h3>
                <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>
                <a routerLink="/business-insurance" class="read-more-btn">Learn More</a>

                <div class="box-shape">
                    <img src="assets/img/box-shape1.png" alt="image">
                    <img src="assets/img/box-shape2.png" alt="image">
                </div>
            </div>

            <div class="single-services-box">
                <div class="icon">
                    <i class="flaticon-health-insurance"></i>

                    <div class="icon-bg">
                        <img src="assets/img/icon-bg1.png" alt="image">
                        <img src="assets/img/icon-bg2.png" alt="image">
                    </div>
                </div>

                <h3><a routerLink="/health-insurance">Health Insurance</a></h3>
                <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>
                <a routerLink="/health-insurance" class="read-more-btn">Learn More</a>

                <div class="box-shape">
                    <img src="assets/img/box-shape1.png" alt="image">
                    <img src="assets/img/box-shape2.png" alt="image">
                </div>
            </div>

            <div class="single-services-box">
                <div class="icon">
                    <i class="flaticon-travel-insurance"></i>

                    <div class="icon-bg">
                        <img src="assets/img/icon-bg1.png" alt="image">
                        <img src="assets/img/icon-bg2.png" alt="image">
                    </div>
                </div>

                <h3><a routerLink="/travel-insurance">Travel Insurance</a></h3>
                <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>
                <a routerLink="/travel-insurance" class="read-more-btn">Learn More</a>

                <div class="box-shape">
                    <img src="assets/img/box-shape1.png" alt="image">
                    <img src="assets/img/box-shape2.png" alt="image">
                </div>
            </div>

            <div class="single-services-box">
                <div class="icon">
                    <i class="flaticon-car-insurance"></i>

                    <div class="icon-bg">
                        <img src="assets/img/icon-bg1.png" alt="image">
                        <img src="assets/img/icon-bg2.png" alt="image">
                    </div>
                </div>

                <h3><a routerLink="/car-insurance">Car Insurance</a></h3>
                <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>
                <a routerLink="/car-insurance" class="read-more-btn">Learn More</a>

                <div class="box-shape">
                    <img src="assets/img/box-shape1.png" alt="image">
                    <img src="assets/img/box-shape2.png" alt="image">
                </div>
            </div>

            <div class="single-services-box">
                <div class="icon">
                    <i class="flaticon-life-insurance"></i>

                    <div class="icon-bg">
                        <img src="assets/img/icon-bg1.png" alt="image">
                        <img src="assets/img/icon-bg2.png" alt="image">
                    </div>
                </div>

                <h3><a routerLink="/life-insurance">Life Insurance</a></h3>
                <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>
                <a routerLink="/life-insurance" class="read-more-btn">Learn More</a>

                <div class="box-shape">
                    <img src="assets/img/box-shape1.png" alt="image">
                    <img src="assets/img/box-shape2.png" alt="image">
                </div>
            </div>

            <div class="single-services-box">
                <div class="icon">
                    <i class="flaticon-agriculture"></i>

                    <div class="icon-bg">
                        <img src="assets/img/icon-bg1.png" alt="image">
                        <img src="assets/img/icon-bg2.png" alt="image">
                    </div>
                </div>

                <h3><a routerLink="/home-insurance">Agri Insurance</a></h3>
                <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>
                <a routerLink="/home-insurance" class="read-more-btn">Learn More</a>

                <div class="box-shape">
                    <img src="assets/img/box-shape1.png" alt="image">
                    <img src="assets/img/box-shape2.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="partner-area">
    <div class="container">
        <div class="partner-title">
            <h2>Our Trusted Partners</h2>
        </div>

        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img1.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img2.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img3.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img4.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img5.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="why-choose-us-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="why-choose-us-slides owl-carousel owl-theme">
                    <div class="why-choose-us-image bg1">
                        <img src="assets/img/why-choose-img1.jpg" alt="image">
                    </div>

                    <div class="why-choose-us-image bg2">
                        <img src="assets/img/why-choose-img2.jpg" alt="image">
                    </div>

                    <div class="why-choose-us-image bg3">
                        <img src="assets/img/why-choose-img3.jpg" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="why-choose-us-content">
                    <div class="content">
                        <div class="title">
                            <span class="sub-title">Your Benefits</span>
                            <h2>Why Choose Us</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>

                        <ul class="features-list">
                            <li>
                                <div class="icon">
                                    <i class="flaticon-like"></i>
                                </div>
                                <span>25 Years of Experience</span>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit
                            </li>

                            <li>
                                <div class="icon">
                                    <i class="flaticon-customer-service"></i>
                                </div>
                                <span>24/7 Support</span>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit
                            </li>

                            <li>
                                <div class="icon">
                                    <i class="flaticon-care"></i>
                                </div>
                                <span>Service with Love</span>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit
                            </li>

                            <li>
                                <div class="icon">
                                    <i class="flaticon-team"></i>
                                </div>
                                <span>Clients Focused</span>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit
                            </li>

                            <li>
                                <div class="icon">
                                    <i class="flaticon-policy"></i>
                                </div>
                                <span>No Policy Fees</span>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit
                            </li>

                            <li>
                                <div class="icon">
                                    <i class="flaticon-education"></i>
                                </div>
                                <span>Growing Your Business</span>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="quote-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="quote-content">
                    <h2>Get A Free Quote</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                    <div class="image">
                        <img src="assets/img/img1.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="tab quote-list-tab">
                    <ul class="tabs">
                        <li><a href="#">Home</a></li>
                        <li><a href="#">Business</a></li>
                        <li><a href="#">Health</a></li>
                        <li><a href="#">Car</a></li>
                        <li><a href="#">Life</a></li>
                    </ul>

                    <div class="tab_content">
                        <div class="tabs_item">
                            <p>Our experts will reply you with a quote very soon</p>
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Your Email">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Phone">
                                </div>
                                <div class="form-group">
                                    <select>
                                        <option>Property Used For</option>
                                        <option>Home Insurance</option>
                                        <option>Business Insurance</option>
                                        <option>Health Insurance</option>
                                        <option>Travel Insurance</option>
                                        <option>Car Insurance</option>
                                        <option>Life Insurance</option>
                                    </select>
                                </div>
                                <button type="submit" class="default-btn">Get A Free Quote <span></span></button>
                            </form>
                        </div>

                        <div class="tabs_item">
                            <p>Our experts will reply you with a quote very soon</p>
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Your Email">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Phone">
                                </div>
                                <div class="form-group">
                                    <select>
                                        <option>Property Used For</option>
                                        <option>Home Insurance</option>
                                        <option>Business Insurance</option>
                                        <option>Health Insurance</option>
                                        <option>Travel Insurance</option>
                                        <option>Car Insurance</option>
                                        <option>Life Insurance</option>
                                    </select>
                                </div>
                                <button type="submit" class="default-btn">Get A Free Quote <span></span></button>
                            </form>
                        </div>

                        <div class="tabs_item">
                            <p>Our experts will reply you with a quote very soon</p>
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Your Email">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Phone">
                                </div>
                                <div class="form-group">
                                    <select>
                                        <option>Property Used For</option>
                                        <option>Home Insurance</option>
                                        <option>Business Insurance</option>
                                        <option>Health Insurance</option>
                                        <option>Travel Insurance</option>
                                        <option>Car Insurance</option>
                                        <option>Life Insurance</option>
                                    </select>
                                </div>
                                <button type="submit" class="default-btn">Get A Free Quote <span></span></button>
                            </form>
                        </div>

                        <div class="tabs_item">
                            <p>Our experts will reply you with a quote very soon</p>
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Your Email">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Phone">
                                </div>
                                <div class="form-group">
                                    <select>
                                        <option>Property Used For</option>
                                        <option>Home Insurance</option>
                                        <option>Business Insurance</option>
                                        <option>Health Insurance</option>
                                        <option>Travel Insurance</option>
                                        <option>Car Insurance</option>
                                        <option>Life Insurance</option>
                                    </select>
                                </div>
                                <button type="submit" class="default-btn">Get A Free Quote <span></span></button>
                            </form>
                        </div>

                        <div class="tabs_item">
                            <p>Our experts will reply you with a quote very soon</p>
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Your Email">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Phone">
                                </div>
                                <div class="form-group">
                                    <select>
                                        <option>Property Used For</option>
                                        <option>Home Insurance</option>
                                        <option>Business Insurance</option>
                                        <option>Health Insurance</option>
                                        <option>Travel Insurance</option>
                                        <option>Car Insurance</option>
                                        <option>Life Insurance</option>
                                    </select>
                                </div>
                                <button type="submit" class="default-btn">Get A Free Quote <span></span></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="ctr-area">
    <div class="container">
        <div class="ctr-content">
            <h2>Insurances For Your Child's Future</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
            <a routerLink="/contact" class="default-btn">Get A Quote <i class="flaticon-right-chevron"></i><span></span></a>
        </div>

        <div class="ctr-image">
            <img src="assets/img/ctr-img.jpg" alt="image">
        </div>

        <div class="shape">
            <img src="assets/img/bg-dot3.png" alt="image">
        </div>
    </div>
</section> -->

<!-- <section class="feedback-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Feedback</span>
            <h2>What Client Says</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="feedback-slides">
            <div class="client-feedback">
                <div>
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                </div>
                
                <button class="prev-arrow slick-arrow">
                    <i class='flaticon-left-chevron'></i>
                </button>
                
                <button class="next-arrow slick-arrow">
                    <i class='flaticon-right-chevron'></i>
                </button>
            </div>
            
            <div class="client-thumbnails">
                <div>
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img2.jpg" alt="client"></div>
                        <div class="title">
                            <h3>Jonus Nathan</h3>
                            <span>CEO at Envato</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img4.jpg" alt="client"></div>
                        <div class="title">
                            <h3>Sadio Finn</h3>
                            <span>CEO at FlatIcon</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img1.jpg" alt="client"></div>
                        <div class="title">
                            <h3>Tom Olivar</h3>
                            <span>CEO at ThemeForest</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img5.jpg" alt="client"></div>
                        <div class="title">
                            <h3>James Finn</h3>
                            <span>CEO at GitLab</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img1.jpg" alt="client"></div>
                        <div class="title">
                            <h3>John Lucy</h3>
                            <span>CEO at Linkedin</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img3.jpg" alt="client"></div>
                        <div class="title">
                            <h3>Sarah Taylor</h3>
                            <span>CEO at Twitter</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img5.jpg" alt="client"></div>
                        <div class="title">
                            <h3>James Anderson</h3>
                            <span>CEO at Facebook</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img3.jpg" alt="client"></div>
                        <div class="title">
                            <h3>Steven Smith</h3>
                            <span>CEO at EnvyTheme</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="our-mission-area">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-3 col-md-6 p-0">
                <div class="mission-image bg-1">
                    <img src="assets/img/mission-img1.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-3 col-md-6 p-0">
                <div class="mission-text">
                    <div class="icon">
                        <i class="flaticon-target"></i>
                    </div>

                    <h3>Our Mission</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p>
                    <a routerLink="/about" class="default-btn">Learn More <span></span></a>

                    <div class="shape"><img src="assets/img/bg-dot2.png" alt="image"></div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 p-0">
                <div class="mission-image bg-2">
                    <img src="assets/img/mission-img2.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-3 col-md-6 p-0">
                <div class="mission-text">
                    <div class="icon">
                        <i class="flaticon-medal"></i>
                    </div>

                    <h3>Our History</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p>
                    <a routerLink="/about" class="default-btn">Learn More <span></span></a>

                    <div class="shape"><img src="assets/img/bg-dot2.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="team-area ptb-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Agent</span>
            <h2>Meet Our Experts</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="team-slides owl-carousel owl-theme">
            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/img2.jpg" alt="image">

                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>

                <div class="content">
                    <h3>Lee Munroe</h3>
                    <span>CEO & Founder</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/img3.jpg" alt="image">

                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>

                <div class="content">
                    <h3>Calvin Klein</h3>
                    <span>Underwriter</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/img4.jpg" alt="image">

                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>

                <div class="content">
                    <h3>Sarah Taylor</h3>
                    <span>Agent</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/img1.jpg" alt="image">

                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>

                <div class="content">
                    <h3>Alastair Cook</h3>
                    <span>Risk Manager</span>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="achievements-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="achievements-content">
                    <div class="title">
                        <span class="sub-title">Number</span>
                        <h2>Our Achievements</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-6 col-sm-4">
                            <div class="single-funfact">
                                <i class="flaticon-flag"></i>
                                <h3><span class="odometer" data-count="65">00</span></h3>
                                <p>Countries</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-6 col-sm-4">
                            <div class="single-funfact">
                                <i class="flaticon-group"></i>
                                <h3><span class="odometer" data-count="107">00</span> <span class="sign-icon">m</span></h3>
                                <p>Clients</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12 col-sm-4">
                            <div class="single-funfact">
                                <i class="flaticon-medal"></i>
                                <h3><span class="odometer" data-count="150">00</span></h3>
                                <p>Wining Awards</p>
                            </div>
                        </div>
                    </div>
                    <div class="bg-dot"><img src="assets/img/bg-dot.png" alt="image"></div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="achievements-image-slides owl-carousel owl-theme">
                    <div class="single-achievements-image bg1">
                        <img src="assets/img/achievements-img1.jpg" alt="image">
                    </div>
                    <div class="single-achievements-image bg2">
                        <img src="assets/img/achievements-img2.jpg" alt="image">
                    </div>
                    <div class="single-achievements-image bg3">
                        <img src="assets/img/achievements-img3.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="blog-area ptb-100 pb-0">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Blog</span>
            <h2>News And Insights</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/img1.jpg" alt="image"></a>
                        <div class="date"><i class="flaticon-timetable"></i> Oct 14, 2020</div>
                    </div>

                    <div class="post-content">
                        <h3><a routerLink="/blog-details">2020 Insurance Trends And Possible Challenges</a></h3>
                        <p>Luis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <a routerLink="/blog-details" class="default-btn">Read More <span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/img2.jpg" alt="image"></a>
                        <div class="date"><i class="flaticon-timetable"></i> Oct 10, 2020</div>
                    </div>

                    <div class="post-content">
                        <h3><a routerLink="/blog-details">Global Trends in the Life Insurance Industry</a></h3>
                        <p>Luis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <a routerLink="/blog-details" class="default-btn">Read More <span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/img3.jpg" alt="image"></a>
                        <div class="date"><i class="flaticon-timetable"></i> Sep 13, 2020</div>
                    </div>

                    <div class="post-content">
                        <h3><a routerLink="/blog-details">The Best Car Insurance Companies in 2020</a></h3>
                        <p>Luis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <a routerLink="/blog-details" class="default-btn">Read More <span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="blog-notes">
                    <p>Insights to help you do what you do better, faster and more profitably. <a routerLink="/blog-grid">Read Full Blog</a></p>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="join-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="join-image text-center">
                    <img src="assets/img/woman.png" alt="image">
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="join-content">
                    <h2>Great Place to Work</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <a routerLink="/contact" class="default-btn">Join Now <i class="flaticon-right-chevron"></i><span></span></a>
                </div>
            </div>
        </div>
    </div>
</section> -->