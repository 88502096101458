<app-header-one></app-header-one>

<div class="page-title-area page-title-bg3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Testimonials</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Testimonials</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="feedback-area ptb-100 bg-fafafa">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Feedback</span>
            <h2>What Client Says</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="feedback-slides">
            <div class="client-feedback">
                <div>
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                </div>
                
                <button class="prev-arrow slick-arrow">
                    <i class='flaticon-left-chevron'></i>
                </button>
                
                <button class="next-arrow slick-arrow">
                    <i class='flaticon-right-chevron'></i>
                </button>
            </div>
            
            <div class="client-thumbnails">
                <div>
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img2.jpg" alt="client"></div>
                        
                        <div class="title">
                            <h3>Jonus Nathan</h3>
                            <span>CEO at Envato</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img4.jpg" alt="client"></div>
                        
                        <div class="title">
                            <h3>Sadio Finn</h3>
                            <span>CEO at FlatIcon</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img1.jpg" alt="client"></div>
                        
                        <div class="title">
                            <h3>Tom Olivar</h3>
                            <span>CEO at ThemeForest</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img5.jpg" alt="client"></div>
                        
                        <div class="title">
                            <h3>James Finn</h3>
                            <span>CEO at GitLab</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img1.jpg" alt="client"></div>
                        
                        <div class="title">
                            <h3>John Lucy</h3>
                            <span>CEO at Linkedin</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img3.jpg" alt="client"></div>
                        
                        <div class="title">
                            <h3>Sarah Taylor</h3>
                            <span>CEO at Twitter</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img5.jpg" alt="client"></div>
                        
                        <div class="title">
                            <h3>James Anderson</h3>
                            <span>CEO at Facebook</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img3.jpg" alt="client"></div>
                        
                        <div class="title">
                            <h3>Steven Smith</h3>
                            <span>CEO at EnvyTheme</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="feedback-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Feedback</span>
            <h2>What Client Says About Us</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="feedback-item-slides owl-carousel owl-theme">
            <div class="single-feedback-item">
                <div class="feedback-desc">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit volupt accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae illo.</p>
                </div>

                <div class="client-info">
                    <img src="assets/img/client-image/img1.jpg" alt="image">
                    <h3>David Luis</h3>
                    <span>Founder & CEO</span>
                </div>
            </div>

            <div class="single-feedback-item">
                <div class="feedback-desc">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit volupt accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae illo.</p>
                </div>

                <div class="client-info">
                    <img src="assets/img/client-image/img2.jpg" alt="image">
                    <h3>Steven Smith</h3>
                    <span>Developer</span>
                </div>
            </div>

            <div class="single-feedback-item">
                <div class="feedback-desc">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit volupt accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae illo.</p>
                </div>

                <div class="client-info">
                    <img src="assets/img/client-image/img3.jpg" alt="image">
                    <h3>Sarah Lucy</h3>
                    <span>Designer</span>
                </div>
            </div>

            <div class="single-feedback-item">
                <div class="feedback-desc">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit volupt accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae illo.</p>
                </div>

                <div class="client-info">
                    <img src="assets/img/client-image/img4.jpg" alt="image">
                    <h3>James Anderson</h3>
                    <span>Manager</span>
                </div>
            </div>
        </div>
    </div>
</section>