<app-header-one></app-header-one>

<div class="page-title-area page-title-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Team</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Team</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="team-area ptb-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/img2.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Lee Munroe</h3>
                        <span>CEO & Founder</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/img3.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Calvin Klein</h3>
                        <span>Underwriter</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/img4.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Sarah Taylor</h3>
                        <span>Risk Manager</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/img1.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Alastair Cook</h3>
                        <span>Agent</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/img5.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Meg Lanning</h3>
                        <span>Agent</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/img6.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>James Anderson</h3>
                        <span>Marketing Support</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="feedback-area ptb-100 bg-fafafa">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Feedback</span>
            <h2>What Client Says</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="feedback-slides">
            <div class="client-feedback">
                <div>
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                </div>
                
                <button class="prev-arrow slick-arrow">
                    <i class='flaticon-left-chevron'></i>
                </button>
                
                <button class="next-arrow slick-arrow">
                    <i class='flaticon-right-chevron'></i>
                </button>
            </div>
            
            <div class="client-thumbnails">
                <div>
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img2.jpg" alt="client"></div>
                        
                        <div class="title">
                            <h3>Jonus Nathan</h3>
                            <span>CEO at Envato</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img4.jpg" alt="client"></div>
                        
                        <div class="title">
                            <h3>Sadio Finn</h3>
                            <span>CEO at FlatIcon</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img1.jpg" alt="client"></div>
                        
                        <div class="title">
                            <h3>Tom Olivar</h3>
                            <span>CEO at ThemeForest</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img5.jpg" alt="client"></div>
                        
                        <div class="title">
                            <h3>James Finn</h3>
                            <span>CEO at GitLab</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img1.jpg" alt="client"></div>
                        
                        <div class="title">
                            <h3>John Lucy</h3>
                            <span>CEO at Linkedin</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img3.jpg" alt="client"></div>
                        
                        <div class="title">
                            <h3>Sarah Taylor</h3>
                            <span>CEO at Twitter</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img5.jpg" alt="client"></div>
                        
                        <div class="title">
                            <h3>James Anderson</h3>
                            <span>CEO at Facebook</span>
                        </div>
                    </div>
                    
                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img3.jpg" alt="client"></div>
                        
                        <div class="title">
                            <h3>Steven Smith</h3>
                            <span>CEO at EnvyTheme</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="partner-area">
    <div class="container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/img1.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/img2.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/img3.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/img4.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/img5.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</section>